import GraphViz from "@/views/graph";

export default {
    name: "map-screen",
    components: {
        GraphViz,
    },
    created() {
        this.$store.dispatch("setCaseId", this.$route.params.id);
    },
    mounted() {},
    data() {
        return {};
    },
};
